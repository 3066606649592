import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Items from '../components/Items'
import Root from '../layouts/Root'
import config from '../config'

const Branches = ({ data }) => (
  <Root>
    {/* prettier-ignore */}
    <Helmet>
      <title>{`Branches | ${config.title}`}</title>
      <meta property="og:title" content={`Branches | ${config.title}`} />
      <meta name="description" content="W&R beveiliging verzorgt de beveiliging voor bedrijven & organisaties. Zoals horeca, zorg, industrie, logistiek, overheid, onderwijs en bouw." />
      <meta property="og:description" content="W&R beveiliging verzorgt de beveiliging voor bedrijven & organisaties. Zoals horeca, zorg, industrie, logistiek, overheid, onderwijs en bouw." />
    </Helmet>

    <main>
      <h1 className="t-heading-l t-center">Branches</h1>

      <Items data={data.branches} maxTwo />
    </main>
  </Root>
)

export const query = graphql`
  query {
    branches: allMarkdownRemark(
      filter: { fields: { slug: { regex: "/^/branches/.+/" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            cover_alt
            cover {
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 400, toFormat: JPG) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

export default Branches
